import React, { CSSProperties, SyntheticEvent, useCallback, useMemo } from "react";

import { Dimmer, Icon, Loader, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";

// Types
type LoadingIconProps = {
  color?: SemanticCOLORS;
  dimmerStyle?: CSSProperties;
  disabled?: boolean;
  inverted?: boolean;
  loading?: boolean;
  name?: SemanticICONS;
  style?: CSSProperties;
  onClick?: (e: SyntheticEvent) => any;
};

const LoadingIcon = (props: LoadingIconProps) => {
  const style = useMemo(() => ({ cursor: "pointer", ...props.style }), [props.style]);

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      props.onClick?.(e);
    },
    [props.onClick]
  );

  // Memo
  const dimmerStyle = useMemo(
    () => ({
      background: "transparent",
      margin: "-1px 0 0 -2px",
      padding: 0,
      ...props.dimmerStyle,
    }),
    []
  );

  return (
    <span style={{ position: "relative" }}>
      <Icon
        color={props.color ?? "blue"}
        disabled={props.disabled}
        name={props.name}
        style={style}
        like
        onClick={handleClick}
      />
      <Dimmer
        active={props.loading}
        inverted={props.inverted ?? true}
        size="mini"
        style={dimmerStyle}
      >
        <Loader size="mini" inline />
      </Dimmer>
    </span>
  );
};

LoadingIcon.displayName = "LoadingIcon";

export default React.memo(LoadingIcon);
